import React from "react";
import "./AdminAddEmployees.css";
import AddEmployees from "../../../Component/Admin/AddEmployees/AddEmployees";
const AdminAddEmployees = () => {
  return (
    <div className="AdminAddEmployees">
      <AddEmployees />
    </div>
  );
};

export default AdminAddEmployees;
